import React, { useEffect, useState } from 'react'

import { WppIconDownload } from '@wppopen/components-library-react'
import DOMPurify from 'dompurify'

import DataGridOfflineComp from 'components/dataGrid/DataGridOfflineComp'
import serviceURL from 'helper/serviceURL'
import useAxiosInterceptors from 'hooks/useAxiosInterceptors'

import styles from './LegacyAssessment.module.scss'
import appConfig from '../../app.config'

interface LegacyAssessmentProps {
  assessmentData: {
    legacyAttachments: {
      [key: string]: string
    }
    id: string
  } | undefined
}

interface DataProps {
  sections: {
    sectionId: string
    header: {
      name: string
    }
    sequence: string
    questions: {
      question: {
        attributes: string
        content: string
        id: string
        sequence: string
      }
      questionResponses: {
        responses: {
          response: string
        }[]
      }[]
      attachmentIds: string[]
    }[]
  }[]
}

function LegacyAssessment({ assessmentData }: Readonly<LegacyAssessmentProps>) {
  const [data, setData] = useState<DataProps>({ sections: [] })
  const [isLoading, setIsLoading] = useState(false)
  const { axiosInstance } = useAxiosInterceptors()

  useEffect(() => {
    if (assessmentData?.id) {
      fetchData(assessmentData.id)
    }
  }, [assessmentData?.id])

  const fetchData = (id: string) => {
    const apiUrl = serviceURL.pgpBaseAPI + `/api/assessments/legacy-data/${id}`
    setIsLoading(true)
    axiosInstance
      .get(apiUrl)
      .then((res) => {
        setData(res?.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  return (
    <>
      {isLoading ? (
        <DataGridOfflineComp />
      ) : (
        <>
          <h1 className={styles.heading}>Assessment Details</h1>
          {data?.sections?.map(section => (
            <div className={styles.box} key={section.sectionId}>
              <h3 className={styles.sectionHeading}>{section?.header?.name}</h3>
              {section?.questions?.map(item => {
                const attributes = JSON.parse(item?.question?.attributes || '{}')
                const isMultiSelect = attributes?.isMultiSelect
                return (
                    <div className={styles.questionBox} key={item?.question?.id}>
                      <span className={styles.sequence}>{`${section?.sequence}.${item?.question?.sequence}`}</span>
                      <div className={styles.description}>
                        <span
                          className={styles.content}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item?.question?.content, {
                              ALLOWED_ATTR: ['href', 'target', 'rel']
                            })
                          }}
                        />
                        <p className={styles.responseBox}>
                          <span className={styles.response}>Response: </span>{' '}
                          <span className={styles.main}>
                            {item?.questionResponses?.[0]?.responses?.map(res => (
                              <span
                                key={res?.response}
                                className={isMultiSelect ? styles.responseAttributeContent : styles.responseContent}
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(res?.response, {
                                    ALLOWED_ATTR: ['href', 'target', 'rel']
                                  })
                                }}
                              />
                            ))}
                          </span>
                        </p>
                        {item?.attachmentIds?.length > 0 && (
                          <div className={styles.attachment}>
                            <span className={styles.attachmentLabel}>Attachment(s): </span>
                            <ul className={styles.attachmentFile}>
                              {item?.attachmentIds?.map(id => (
                                <li key={id}>
                                  {' '}
                                  {assessmentData?.legacyAttachments?.[id] && (<a
                                    href={
                                      appConfig.RA_HOST_URL +
                                      '/api/responses/download?filename=' +
                                      assessmentData?.legacyAttachments?.[id]
                                    }
                                    className={styles.downloadLink}
                                  >
                                    {assessmentData?.legacyAttachments?.[id]}{' '}
                                    <WppIconDownload style={{ cursor: 'pointer' }} color="primary" size="s" />
                                  </a>)}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                )
              })}
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default LegacyAssessment
