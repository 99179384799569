import React from 'react'

import { WppButton } from '@wppopen/components-library-react'
import jsPDF from 'jspdf'

import 'jspdf-autotable'
import { QUE_TYPE } from 'helper/constants'
import { getLocalDate, uploadFileName } from 'helper/Helper'

import styles from './pdf.module.scss'

interface SummaryProps {
  template: {
    logoUrl: string
  }
  inventoryType: {
    businessName: string
  }
  name: string
  description: string
  status: string
  createdBy: string
  createdAt: string
  modifiedAt: string
  reviewedBy: string
  reviewerStatus: string
  reviwerActionAt: string
  inventoryName: string
  respondents: { email: string }[]
}

interface DataProps {
  assessmentId: string;
  assessmentName?: string;
  isAllQnNonEmpty?: boolean;
  inventoryName: string|null;
  details: {
    categoryName: string
    questionAnswers: {
      question: {
        sequence: string
        description: string
        subDescription: string
        questionType: string
      }
      response: {
        responseValue: string
      }
      comments: {
        comment: string
        createdBy: string
        received: string
        reviewer: boolean
      }[]
      riskDetails: {
        riskLevel: string
      }
      attachments: { filename: string }[]
    }[]
  }[]
}
interface Content {
  type: string
  text?: string | undefined
  subDescription?: string
  values?: string[]
  additional?: string
  risk?: string
  reviewer?: boolean
  time?: string | undefined | null
  createdBy?: string
  attachments?: { filename: string }[]
}
const generateContentForPDF = (data: DataProps) => {
  const content: Content[] = []
  data?.details?.forEach((category, index) => {
    if (category?.categoryName !== 'Data Privacy Team Only') {
      content.push({ type: 'title', text: `${index + 1}. ${category?.categoryName}` })

      category?.questionAnswers?.forEach(item => {
        content.push({
          type: 'question',
          text: `${item?.question?.sequence} ${item?.question?.description}`,
          subDescription: item?.question?.subDescription
        })

        if (
          [
            QUE_TYPE.SINGLE_SELECT,
            QUE_TYPE.SINGLE_SELECT_TEXT,
            QUE_TYPE.SINGLE_SELECT_TEXT_CONDITIONAL,
            QUE_TYPE.SINGLE_SELECT_TEXT_OPTIONAL
          ].includes(item.question.questionType)
        ) {
          const response = item?.response?.responseValue?.split('$$')
          content.push({
            type: 'response',
            text: response[0],
            additional: response[1],
            risk: item?.riskDetails?.riskLevel,
            attachments: item?.attachments
          })
        }

        if (
          [
            QUE_TYPE.MULTI_SELECT,
            QUE_TYPE.MULTI_SELECT_TEXT,
            QUE_TYPE.MULTI_SELECT_TEXT_CONDITIONAL,
            QUE_TYPE.MULTI_SELECT_TEXT_OPTIONAL
          ].includes(item.question.questionType)
        ) {
          const response = item?.response?.responseValue?.split('$$')
          content.push({
            type: 'response-multi',
            values: response[0]?.split('|'),
            additional: response[1],
            risk: item?.riskDetails?.riskLevel,
            attachments: item?.attachments
          })
        }

        if (item.question.questionType === 'TEXT') {
          content.push({
            type: 'text-response',
            text: item?.response?.responseValue,
            risk: item?.riskDetails?.riskLevel,
            attachments: item?.attachments
          })
        }

        if (item?.comments?.length > 0) {
          content.push({ type: 'comments-header', text: 'Comments History' })
          item?.comments?.forEach(comment => {
            content.push({
              type: 'comment',
              reviewer: comment?.reviewer,
              text: comment?.comment,
              time: getLocalDate(comment?.received, true),
              createdBy: comment?.createdBy
            })
          })
        }
      })
    }
  })
  return content
}

export async function imageUrlToBase64(url: string): Promise<string> {
  try {
    if(!url) return ''
    const response = await fetch(url)
    if (!response.ok) throw new Error(`Failed to fetch image: ${response.statusText}`)
    const blob = await response.blob()

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = error => reject(error)
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    console.error('Error converting image to Base64:', error)
    return Promise.reject(error)
  }
}

export const generatePDF = async (data: DataProps, summaryData: SummaryProps) => {
  const pdf = new jsPDF('p', 'mm', 'a4')
  const margin = 10
  const lineHeight = 10
  const pageWidth = pdf.internal.pageSize.getWidth()
  const usableWidth = pageWidth - margin * 2
  const pageHeight = pdf.internal.pageSize.getHeight()
  let yOffset = margin
  let logoBase64 = '';
  try {
    logoBase64 = await imageUrlToBase64(summaryData?.template?.logoUrl);
  } catch (error) {
    console.warn('Logo image could not be loaded, skipping logo:', error);
  }

  const logoY = 10 // Y-coordinate (margin from the top)
  const logoWidth = 70 // Width of the logo
  const logoHeight = 20 // Height of the logo
  const logoX = (pageWidth - logoWidth) / 2

  if (logoBase64) {
    pdf.addImage(logoBase64, 'PNG', logoX, logoY, logoWidth, logoHeight);
    yOffset = logoY + logoHeight
  }

  const content = generateContentForPDF(data)

  pdf.setFontSize(12)
  pdf.setTextColor(63, 81, 181)
  pdf.setFont('helvetica', 'bold')
  const text = 'Assessment Report';
  const textWidth = pdf.getTextWidth(text); 
  const xCenter = (pageWidth - textWidth) / 2
  pdf.text('Assessment Report', xCenter, yOffset + 20)
  yOffset += 10
  pdf.setFontSize(11)
  pdf.setTextColor(0, 0, 0)
  const title = summaryData.name;
  const wrappedText = pdf.splitTextToSize(title, usableWidth);
  wrappedText.forEach((line: string, index: number) => {
    const textWidth = pdf.getTextWidth(line); 
    const xCenter = (pageWidth - textWidth) / 2; 
    pdf.text(line, xCenter, yOffset + 20 + index * 10); 
  });
  yOffset += wrappedText?.length > 1 ? 20 : 10

  pdf.setFontSize(9)
  pdf.setTextColor(0, 0, 0)
  pdf.setFont('helvetica')
  pdf.setTextColor(147, 64, 116)
  pdf.text('Vendor Details', margin, yOffset + 20)
  yOffset += 30

  const vendorheaderData = [['Name:', summaryData.inventoryName || 'N/A']]

  pdf.autoTable({
    body: vendorheaderData,
    startY: yOffset,
    theme: 'grid',
    bodyStyles: { fillColor: [255, 255, 255] },
    styles: { fontSize: 9, textColor: [0, 0, 0], overflow: 'linebreak' },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: pageWidth * 0.3 },
      1: { textColor: [0, 0, 0], cellWidth: pageWidth * 0.6 }
    },
    tableWidth: 'auto',
    margin: { left: margin, right: margin }
  })

  pdf.autoTable({
    body: vendorheaderData,
    startY: yOffset,
    theme: 'grid',
    bodyStyles: { fillColor: [255, 255, 255] },
    styles: { fontSize: 9, textColor: [0, 0, 0], overflow: 'linebreak' },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: pageWidth * 0.3 },
      1: { textColor: [0, 0, 0], cellWidth: pageWidth * 0.6 }
    },
    tableWidth: 'auto',
    margin: { left: margin, right: margin }
  })

  yOffset = (pdf as any).autoTable.previous.finalY

  pdf.setFontSize(9)
  pdf.setTextColor(0, 0, 0)
  pdf.setFont('helvetica')
  pdf.setTextColor(246, 183, 90)
  pdf.text('Assessment Details', margin, yOffset + 20)
  yOffset += 30

  const headerData = [
    ['Name:', summaryData.name || 'N/A'],
    ['Description:', summaryData.description || 'N/A'],
    ['Status:', summaryData.status],
    ['Created By:', summaryData.createdBy],
    ['Created At:', summaryData.createdAt ? getLocalDate(summaryData?.createdAt, true) : ''],
    ['Inventory Type:', summaryData.inventoryType?.businessName || ''],
    ['Last Activity Date:', summaryData?.modifiedAt ? getLocalDate(summaryData?.modifiedAt, true) : ''],
    ['Reviewed By:', summaryData?.reviewedBy || ''],
    ['Reviewer Action:', summaryData?.reviewerStatus || ''],
    ['Reviewed At:', getLocalDate(summaryData?.reviwerActionAt, true) ?? ''],
    [
      'Respondents:',
      summaryData?.respondents.length
        ? summaryData?.respondents.map((respondent: { email: string }) => respondent.email).join(', ')
        : 'N/A'
    ]
  ]

  pdf.autoTable({
    body: headerData,
    startY: yOffset,
    theme: 'grid',
    bodyStyles: { fillColor: [255, 255, 255] },
    styles: { fontSize: 9, textColor: [0, 0, 0], overflow: 'linebreak' },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: pageWidth * 0.3 },
      1: { textColor: [0, 0, 0], cellWidth: pageWidth * 0.6 }
    },
    tableWidth: 'auto',
    margin: { left: margin, right: margin }
  })

  yOffset = (pdf as any).autoTable.previous.finalY + margin
  pdf.addPage()
  yOffset = margin

  content.forEach(item => {
    const checkPageBreak = (blockHeight: number) => {
      if (yOffset + blockHeight >= pageHeight - margin) {
        pdf.addPage();
        yOffset = margin; // Reset yOffset for the new page 
      }
    };

    // Add title
    if (item.type === 'title') {
      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(11)
      pdf.setTextColor(21, 94, 149)
      const lines = item.text && pdf.splitTextToSize(item.text, usableWidth)
      const blockHeight = lines.length * lineHeight;
      checkPageBreak(blockHeight);
      pdf.text(lines, margin, yOffset)
      yOffset += blockHeight
    }

    // Add questions
    else if (item.type === 'question') {
      pdf.setFont('helvetica', 'normal')
      pdf.setFontSize(10)
      pdf.setTextColor(0, 0, 0)
      const questionLines = item.text && pdf.splitTextToSize(item.text, usableWidth)
      const blockHeight = questionLines.length * lineHeight;
      checkPageBreak(blockHeight)
      pdf.text(questionLines, margin, yOffset)
      yOffset += (blockHeight) / 2 

      if (item.subDescription && !(item.subDescription.includes("target='_target'"))) {
        pdf.setFont('helvetica', 'italic')
        pdf.setFontSize(9)
        const subDescriptionLines = pdf.splitTextToSize(item.subDescription, usableWidth)
        const subDescriptionHeight = subDescriptionLines.length > 10
        ? (subDescriptionLines.length * lineHeight) / 2.5
        : (subDescriptionLines.length * lineHeight) / 2;
        checkPageBreak(subDescriptionHeight);
        pdf.text(subDescriptionLines, margin, yOffset)
        yOffset += subDescriptionHeight
      }
    }

    // Add responses
    else if (item.type === 'response') {
      if (item.risk) {
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)

        const riskLabel = 'Risk: '
        const riskValue = item.risk
        const riskHeight = lineHeight / 2;
        checkPageBreak(riskHeight)
        pdf.setTextColor(0, 0, 0)
        pdf.text(riskLabel, margin, yOffset)

        const riskLabelWidth = pdf.getTextWidth(riskLabel)

        if (riskValue === 'VERY_HIGH') {
          pdf.setTextColor(141, 38, 35)
        } else if (riskValue === 'HIGH') {
          pdf.setTextColor(209, 96, 93)
        } else if (riskValue === 'MEDIUM') {
          pdf.setTextColor(246, 183, 90)
        } else if (riskValue === 'Low') {
          pdf.setTextColor(0, 128, 0)
        } else {
          pdf.setTextColor(204, 204, 204)
        }

        pdf.text(riskValue, margin + riskLabelWidth, yOffset)

        pdf.setTextColor(0, 0, 0)

        yOffset += riskHeight
      }

      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(9)
      pdf.setTextColor(21, 128, 61)
      const responseLabel = 'Response: '
      const responseValue = item.text

      const responseLines = pdf.splitTextToSize(`${responseValue}`, usableWidth);
      const responseHeight = responseLines.length < 2 ? lineHeight : responseLines.length * lineHeight / 2;

      checkPageBreak(responseHeight);
      pdf.text(responseLabel, margin, yOffset)

      const responseLabelWidth = pdf.getTextWidth(responseLabel)

      pdf.setFont('helvetica', 'normal')
      pdf.setFontSize(9)
      pdf.setTextColor(0, 0, 0)
      pdf.text(responseLines, margin + responseLabelWidth, yOffset)
      yOffset += responseHeight

      if (item.additional) {
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)

        pdf.setFont('helvetica', 'italic')
        const additionalTextLines = pdf.splitTextToSize(item.additional, usableWidth)
        const additionalTextHeight = additionalTextLines.length < 2 ? additionalTextLines.length * lineHeight : additionalTextLines.length * lineHeight / 2

        checkPageBreak(additionalTextHeight + 10);
        pdf.rect(margin, yOffset, usableWidth, additionalTextHeight)
        pdf.text(additionalTextLines, margin + 2, yOffset + 4)

        yOffset += additionalTextHeight + 10
      }

      if(item?.attachments && item.attachments?.length > 0) {
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)
        pdf.setTextColor(133, 8, 232)
        const responseLabel = 'Attachments: '
        pdf.text(responseLabel, margin, yOffset)
        yOffset += lineHeight/2
        item.attachments.forEach((attachment, index) => {
          pdf.setFont('helvetica', 'normal')
          pdf.setTextColor(0, 0, 0)
          const valueLines = pdf.splitTextToSize(`• ${uploadFileName(attachment?.filename)}`, usableWidth)
          const attachmentHeight = item.attachments && (item.attachments.length - 1 === index) ? valueLines.length * lineHeight : valueLines.length * lineHeight/2
          checkPageBreak(attachmentHeight);
          pdf.text(valueLines, margin + 5, yOffset)
          yOffset += attachmentHeight
        })
      }
    }

    // Add multi-select responses
    else if (item.type === 'response-multi') {
      if (item.risk) {
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)

        const riskLabel = 'Risk: '
        const riskValue = item.risk
        const riskHeight = lineHeight / 2;
        checkPageBreak(riskHeight)
        pdf.setTextColor(0, 0, 0)
        pdf.text(riskLabel, margin, yOffset)

        const riskLabelWidth = pdf.getTextWidth(riskLabel)

        if (riskValue === 'VERY_HIGH') {
          pdf.setTextColor(141, 38, 35)
        } else if (riskValue === 'HIGH') {
          pdf.setTextColor(209, 96, 93)
        } else if (riskValue === 'MEDIUM') {
          pdf.setTextColor(246, 183, 90)
        } else if (riskValue === 'LOW') {
          pdf.setTextColor(0, 128, 0)
        } else {
          pdf.setTextColor(204, 204, 204)
        }

        pdf.text(riskValue, margin + riskLabelWidth, yOffset)

        pdf.setTextColor(0, 0, 0)
        yOffset += riskHeight
      }

      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(9)
      pdf.setTextColor(21, 128, 61)
      pdf.text('Response:', margin, yOffset)
      yOffset += lineHeight
      item?.values &&
        item.values.forEach((value, index) => {
          pdf.setFont('helvetica', 'normal')
          pdf.setTextColor(0, 0, 0)
          const valueLines = pdf.splitTextToSize(`• ${value}`, usableWidth)
          const multiResponseHeight = item?.values && (item.values.length - 1 === index) ? valueLines.length * lineHeight : valueLines.length * lineHeight/2
          checkPageBreak(multiResponseHeight);
          pdf.text(valueLines, margin + 5, yOffset)
          yOffset += multiResponseHeight
        })

      if (item.additional) {
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)

        yOffset += lineHeight / 2

        pdf.setFont('helvetica', 'italic')
        const additionalTextLines = pdf.splitTextToSize(item.additional, usableWidth)
        const additionalTextHeight = additionalTextLines.length < 2 ? additionalTextLines.length * lineHeight : additionalTextLines.length * lineHeight / 2

        checkPageBreak(additionalTextHeight + 10);
        const horizontalPadding = 2
        const borderWidth = usableWidth - 2 * horizontalPadding

        pdf.rect(margin, yOffset, usableWidth, additionalTextHeight)

        pdf.text(additionalTextLines, margin + horizontalPadding, yOffset + 4, { maxWidth: borderWidth })

        yOffset += additionalTextHeight + 10
      }

      if(item?.attachments && item.attachments?.length > 0) {
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)
        pdf.setTextColor(133, 8, 232)
        const responseLabel = 'Attachments: '
        pdf.text(responseLabel, margin, yOffset)
        yOffset += lineHeight/2
        item.attachments.forEach((attachment, index) => {
          pdf.setFont('helvetica', 'normal')
          pdf.setTextColor(0, 0, 0)
          const valueLines = pdf.splitTextToSize(`• ${uploadFileName(attachment?.filename)}`, usableWidth)
          const attachmentHeight = item.attachments && (item.attachments.length - 1 === index) ? valueLines.length * lineHeight : valueLines.length * lineHeight/2
          checkPageBreak(attachmentHeight);
          pdf.text(valueLines, margin + 5, yOffset)
          yOffset += valueLines.length * lineHeight
        })
      }
    } else if (item.type === 'text-response') {
      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(9)
      pdf.setTextColor(21, 128, 61)
      pdf.text('Response:', margin, yOffset)
      yOffset += lineHeight / 2

      pdf.setFont('helvetica', 'italic')
      pdf.setTextColor(0, 0, 0)
      const textLines = item?.text && pdf.splitTextToSize(item?.text, usableWidth)
      const textHeight = textLines.length < 2 ? textLines.length * lineHeight : textLines.length * lineHeight / 2
      checkPageBreak(textHeight);
      pdf.rect(margin, yOffset, usableWidth, textHeight)

      pdf.text(textLines, margin + 2, yOffset + 4)

      yOffset += textHeight + 15


      if(item?.attachments && item.attachments?.length > 0) {
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)
        pdf.setTextColor(133, 8, 232)
        const responseLabel = 'Attachments: '
        pdf.text(responseLabel, margin, yOffset)
        yOffset += lineHeight/2
        item.attachments.forEach((attachment, index) => {
          pdf.setFont('helvetica', 'normal')
          pdf.setTextColor(0, 0, 0)
          const valueLines = pdf.splitTextToSize(`• ${uploadFileName(attachment?.filename)}`, usableWidth)
          const attachmentHeight = item.attachments && (item.attachments.length - 1 === index) ? valueLines.length * lineHeight : valueLines.length * lineHeight/2
          checkPageBreak(attachmentHeight);
          pdf.text(valueLines, margin + 5, yOffset)
          yOffset += attachmentHeight
        })
      }
    }

    // Add comments
    else if (item.type === 'comments-header') {
      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(9)
      const headerLines = item.text && pdf.splitTextToSize(item.text, usableWidth)
      pdf.setTextColor(2, 136, 209)
      const commentHeaderHeight = headerLines.length * lineHeight
      checkPageBreak(commentHeaderHeight);
      pdf.text(headerLines, margin, yOffset)
      yOffset += commentHeaderHeight
    } else if (item.type === 'comment') {
      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(9)

      if (item.reviewer) {
        const reviewerLines = pdf.splitTextToSize(`Reviewer (${item?.createdBy})`, usableWidth)
        pdf.setTextColor(246, 183, 90)
        pdf.text(reviewerLines, margin, yOffset)
        yOffset += (reviewerLines.length * lineHeight) / 2

        const timeLabel = 'Time: '
        const timeValue = item?.time

        pdf.setTextColor(0, 0, 0)
        const reviewerHeight = lineHeight / 2
        checkPageBreak(reviewerHeight);
        pdf.text(timeLabel, margin, yOffset)

        const timeLabelWidth = pdf.getTextWidth(timeLabel)
        pdf.setTextColor(21, 128, 61)

        timeValue && pdf.text(timeValue, margin + timeLabelWidth, yOffset)
        yOffset += reviewerHeight
      } else {
        const reviewerLines = pdf.splitTextToSize('Vendor', usableWidth)
        pdf.setTextColor(147, 64, 116)
    
        pdf.text(reviewerLines, margin, yOffset) // Add text
        yOffset += (reviewerLines.length * lineHeight) / 2

        const timeLabel = 'Time: '
        const timeValue = item?.time

        pdf.setTextColor(0, 0, 0)
        const vendorHeight = lineHeight / 2
        checkPageBreak(vendorHeight);
        pdf.text(timeLabel, margin, yOffset)

        const timeLabelWidth = pdf.getTextWidth(timeLabel)
        pdf.setTextColor(21, 128, 61)

        timeValue && pdf.text(timeValue, margin + timeLabelWidth, yOffset)
        yOffset += vendorHeight
      }
      pdf.setFont('helvetica', 'normal')
      pdf.setFontSize(9)
      pdf.setTextColor(0, 0, 0)
      const commentLines = item.text && pdf.splitTextToSize(item.text, usableWidth)
      const commentHeight = commentLines.length < 3 ? commentLines.length * lineHeight : (commentLines.length * lineHeight) / 2
      checkPageBreak(commentHeight);
      pdf.text(commentLines, margin, yOffset)
      yOffset += commentHeight
    }

  })

  const fileName = summaryData?.name
    ? `${summaryData.name.toLowerCase().trim()}full_assessment_report.pdf`
    : 'full_assessment_report.pdf'

  pdf.save(fileName) // Save the PDF
}

const FullAssessmentPdf = ({ data, summaryData }: { data: DataProps; summaryData: SummaryProps }) => {
  return (
    <WppButton
      className={styles.pdfBtnAssesment}
      variant="secondary"
      onClick={() => generatePDF(data, summaryData)}
      data-testid="assessment-export-pdf"
    >
      Export Full Assessment
    </WppButton>
  )
}

export default FullAssessmentPdf
