import React from 'react'

import { WppSkeleton } from '@wppopen/components-library-react'

import styles from './DataGridOfflineComp.module.scss'

const DataGridOfflineComp = () => {
  return (
    <div className={styles.offlineTableContainer} data-testid='skeleton-loader'>
      <div className={styles.offlineTableHeader}>
        {[...new Array(8)].map((ar, idx) => {
          return (
            <WppSkeleton
              variant="rectangle"
              width="12%"
              height="2rem"
              className={styles.wppSkeletonHeaderAnimation}
              key={idx}
            />
          )
        })}
      </div>
      <div className={styles.offlineTableBody}>
        {[...new Array(10)].map((ar, idx) => {
          return (
            <WppSkeleton
              variant="rectangle"
              width="100%"
              height="2rem"
              className={styles.wppSkeletonTableAnimation}
              key={idx}
            />
          )
        })}
      </div>
    </div>
  )
}

export default DataGridOfflineComp
